import { j as e } from "./jsx-runtime-C8rcB1Lr.js";
import { I as x, O as d, B as p } from "./index.esm-CHSAHc-R.js";
import { i } from "./index-BGIbvDRY.js";
function N({ title: a, description: l, showDetails: r, reason: s, id: t, actionText: n, showAction: o, actionHandler: c, customClass: m = "" }) {
  return /* @__PURE__ */ e.jsxs("div", {
    className: m,
    children: [
      /* @__PURE__ */ e.jsx(x, {
        name: "Notfound",
        className: "mx-auto mb-4 block h-12 w-12"
      }),
      /* @__PURE__ */ e.jsx("h3", {
        className: "text-primary-DN0 text-center font-semibold text-size-20 pb-2 font-opensans dark:text-primary-DN110",
        children: a
      }),
      /* @__PURE__ */ e.jsx("p", {
        className: "mb-6 text-primary-N60 text-center text-size-14 mt-0",
        children: l
      }),
      /* @__PURE__ */ e.jsx("div", {
        children: r && /* @__PURE__ */ e.jsx(d, {
          tableData: [
            {
              label: i._({
                id: "GLgH/b"
              }),
              value: s || "-"
            },
            {
              label: i._({
                id: "l0QsHi"
              }),
              value: t || "-"
            }
          ]
        })
      }),
      o && /* @__PURE__ */ e.jsx("div", {
        children: /* @__PURE__ */ e.jsx(p, {
          fullwidth: !0,
          onClick: c,
          children: n
        })
      })
    ]
  });
}
export {
  N as ErrorPage
};
