import { KycRequestConfigurationResponse } from "@fintechprimitives/fpapi";
import { KycRequestConfigurationType } from "./types";
import { hasAllGivenPropertiesInObject } from "../../../../../utils/utils";

const isCybrillaOpat = (kycRequestConfigurations: KycRequestConfigurationResponse | undefined) => {
  if (kycRequestConfigurations?.gateway === "opat") {
    return true;
  }

  // Old cybrilla default will have this config
  if (kycRequestConfigurations?.gateway === null && kycRequestConfigurations.settings === null) {
    return true;
  }
};

export const getKycConfigurationType = (
  kycRequestConfigurations: KycRequestConfigurationResponse | undefined
): KycRequestConfigurationType | undefined => {
  if (isCybrillaOpat(kycRequestConfigurations)) {
    return "CYBRILLA_OPAT";
  }

  if (
    hasAllGivenPropertiesInObject(
      ["username", "password", "kra"],
      kycRequestConfigurations?.settings
    )
  ) {
    return "DISTRIBUTOR_AMC";
  }

  if (
    hasAllGivenPropertiesInObject(
      ["poscode", "cvl_sftp_upload_username", "cvl_sftp_upload_password"],
      kycRequestConfigurations?.settings
    )
  ) {
    return "OPAT_AMC";
  }
};
