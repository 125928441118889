import { ReactNode } from "react";
import { Role } from "../context/userinfo/types";
import { useUserInfo } from "../context/userinfo/useUserInfo";
import { ConditionalRenderer } from "../utils/helpers";
import { isAuthorisedRole } from "../utils/utils";

interface AccessRole {
  role: Role[];
  resource: string;
}

type AccessRolesArray = AccessRole[];

//resource format: {FileName}.{Section/ Subsection - to be hidden behind roles}
const accessRolesArray: AccessRolesArray = [
  {
    role: [Role.OPERATIONS, Role.CYBRILLA_ADMIN, Role.TENANT_ROOT],
    resource: "OrderOperations.Actions",
  },
  {
    role: [Role.OPERATIONS, Role.CYBRILLA_ADMIN, Role.TENANT_ROOT],
    resource: "DepositoryFilesUpload.ViewUploadedFiles",
  },
  {
    role: [Role.OPERATIONS, Role.CYBRILLA_ADMIN, Role.TENANT_ROOT],
    resource: "UploadFolioFeeds.ViewUploadedFiles",
  },
  {
    role: [Role.OPERATIONS, Role.CYBRILLA_ADMIN, Role.TENANT_ROOT],
    resource: "Cams.ViewUploadedFiles",
  },
  {
    role: [Role.OPERATIONS, Role.CYBRILLA_ADMIN, Role.TENANT_ROOT],
    resource: "Kfin.ViewUploadedFiles",
  },
  {
    role: [Role.OPERATIONS, Role.CYBRILLA_ADMIN, Role.TENANT_ROOT],
    resource: "UploadFailedOrderFeeds.ViewUploadedFiles",
  },
  {
    role: [Role.OPERATIONS, Role.CYBRILLA_ADMIN, Role.TENANT_ROOT],
    resource: "UploadNigoFeeds.ViewUploadedFiles",
  },
  {
    role: [Role.OPERATIONS, Role.CYBRILLA_ADMIN, Role.TENANT_ROOT],
    resource: "UploadReverseFeeds.ViewUploadedFiles",
  },
  {
    role: [Role.OPERATIONS, Role.CYBRILLA_ADMIN],
    resource: "OrderOperationsDownload.OrderFeedDownload",
  },
  {
    role: [Role.OPERATIONS, Role.CYBRILLA_ADMIN, Role.TENANT_ROOT],
    resource: "OrderOperationsDownload.InvestorFilesDownload",
  },
  {
    role: [Role.OPERATIONS, Role.CYBRILLA_ADMIN, Role.TENANT_ROOT],
    resource: "OrderOperationsUpload.UploadReverseFeeds",
  },
  {
    role: [Role.OPERATIONS, Role.CYBRILLA_ADMIN, Role.TENANT_ROOT],
    resource: "OrderOperationsUpload.UploadNigoFeeds",
  },
  {
    role: [Role.OPERATIONS, Role.CYBRILLA_ADMIN, Role.TENANT_ROOT],
    resource: "OrderOperationsUpload.UploadReverseFeedsPOA",
  },
  {
    role: [Role.OPERATIONS, Role.CYBRILLA_ADMIN, Role.TENANT_ROOT],
    resource: "OrderOperationsUpload.DepositoryFilesUpload",
  },
  {
    role: [Role.CUSTOMER_SUCCESS, Role.CYBRILLA_ADMIN, Role.TENANT_ROOT],
    resource: "SettlementDetails.ApiLogsLink",
  },
  {
    role: [Role.CYBRILLA_ADMIN, Role.CUSTOMER_SUCCESS],
    resource: "PartnersListing.Actions",
  },
  {
    role: [
      Role.CYBRILLA_ADMIN,
      Role.CUSTOMER_SUCCESS,
      Role.OPERATIONS,
      Role.TENANT_OPERATIONS,
      Role.TENANT_ROOT,
      Role.TENANT_WORKFORCE,
    ],
    resource: "routeHelpers.ProtectedExternalLink",
  },
  {
    role: [
      Role.THIRDEYE_MANAGER,
      Role.THIRDEYE_ANALYST,
      Role.THIRDEYE_OPERATIONS,
      Role.THIRDEYE_SQLLAB,
    ],
    resource: "routeHelpers.ProtectedExternalLinkButton",
  },
  {
    role: [
      Role.CYBRILLA_ADMIN,
      Role.CUSTOMER_SUCCESS,
      Role.OPERATIONS,
      Role.TENANT_OPERATIONS,
      Role.TENANT_ROOT,
      Role.TENANT_WORKFORCE,
      Role.THIRDEYE_MANAGER,
      Role.THIRDEYE_ANALYST,
      Role.THIRDEYE_OPERATIONS,
      Role.THIRDEYE_SQLLAB,
    ],
    resource: "NavItems.ProtectedExternalApplicationsTitle",
  },
  {
    role: [Role.CYBRILLA_ADMIN, Role.OPERATIONS, Role.CUSTOMER_SUCCESS],
    resource: "PaymentsGatewayService.BankSettings",
  },
];

export const RenderByUserRole = ({
  resource,
  children,
}: {
  readonly resource: string;
  readonly children: ReactNode;
}) => {
  const userInfo = useUserInfo();
  const currentRoles = userInfo?.data.userInfo?.roles;

  const currentAccessRole = accessRolesArray.find((role) => role.resource === resource);

  if (!currentAccessRole) {
    return null;
  }

  const allowedRoles = currentAccessRole.role;

  return (
    <ConditionalRenderer check={isAuthorisedRole(allowedRoles, currentRoles)}>
      {children}
    </ConditionalRenderer>
  );
};
