export enum Role {
  THIRDEYE_ANALYST = "analyst",
  CUSTOMER_SUCCESS = "cyb-customer-success",
  OPERATIONS = "cyb-operations",
  TENANT_WORKFORCE = "tnt-workforce",
  TENANT_ASSOCIATE_WORKFORCE = "tnt-associate-workforce",
  CYBRILLA_ADMIN = "cyb-admin",
  TENANT_OPERATIONS = "tnt-operations",
  TENANT_ROOT = "tnt-root",
  TENANT_PARTNER = "tnt-partner",
  THIRDEYE_MANAGER = "manager",
  THIRDEYE_OPERATIONS = "operations",
  THIRDEYE_SQLLAB = "sqllab",
  TENANT_INVESTOR = "tnt-investor",

  // amc
  OPERATIONS_ANALYST = "cyb-operations-analyst",
  TENANT_OPERATIONS_ANALYST = "tnt-operations-analyst",
  TENANT_BUSINESS_ANALYST = "tnt-business-analyst",
}

export type Roles = Role[];

export type UserInfoResponse = {
  sub: string;
  tenants: string[];
  email_verified: boolean;
  roles: Roles;
  given_name: string;
  family_name: string;
  email: string;
  type: "internal" | "workforce";
  fp_identifier?: string;
};

export const RoleLabelMap: Partial<Record<Role, string>> = {
  analyst: "thirdeye analyst",
  operations: "thirdeye operations",
  "cyb-customer-success": "customer success",
  "cyb-admin": "cybrilla admin",
  "cyb-operations": "operations",
  "tnt-workforce": "workforce",
  "tnt-associate-workforce": "associate workforce",
  "tnt-operations": "tenant operations",
  "tnt-root": "tenant root",
  "tnt-partner": "tenant partner",
  "tnt-investor": "tenant investor",

  // amc
  "tnt-operations-analyst": "tenant operations analyst",
  "cyb-operations-analyst": "operations analyst",
  "tnt-business-analyst": "tenant business analyst",
};

export const RoleDescriptionMap: Partial<Record<Role, string>> = {
  analyst: "Provides access to the BI tool, including SQL Lab, for querying and analyzing data",
  operations: "Provides access to the BI tool but without SQL Lab access for querying data",
  "cyb-customer-success": "",
  "cyb-admin": "",
  "cyb-operations": "",
  "tnt-workforce":
    "Grants access to view investors, orders and payments data where investors' personal data(PII) is not masked",
  "tnt-associate-workforce":
    "Grants access to view investors, orders, and payments data where investors' personal data(PII) is masked",
  "tnt-operations": "Allows uploading of folio feed files",
  "tnt-root": "",
  "tnt-partner": "",
  "tnt-investor": "",

  // amc
  "tnt-operations-analyst":
    "Provides access to order processing metrics to streamline and accelerate operations",
  "cyb-operations-analyst": "",
  "tnt-business-analyst":
    "Provides access to metrics information to analyze performance and usage analytics",
};

export const TenantPartnerRoles: Role[] = [Role.TENANT_PARTNER];

export const TENANT_ROLES: readonly Role[] = [
  Role.TENANT_ASSOCIATE_WORKFORCE,
  Role.THIRDEYE_ANALYST,
  Role.TENANT_ROOT,
  Role.TENANT_PARTNER,
  Role.TENANT_BUSINESS_ANALYST,
  Role.TENANT_INVESTOR,
  Role.THIRDEYE_OPERATIONS,
  Role.TENANT_WORKFORCE,
  Role.TENANT_OPERATIONS,
  Role.TENANT_OPERATIONS_ANALYST,
  Role.THIRDEYE_ANALYST,
  Role.THIRDEYE_MANAGER,
  Role.THIRDEYE_OPERATIONS,
  Role.THIRDEYE_SQLLAB,
];

export const TenantRootRoles: Role[] = [Role.TENANT_ROOT];

export const InternalRoles: Role[] = [
  Role.THIRDEYE_ANALYST,
  Role.THIRDEYE_OPERATIONS,
  Role.CUSTOMER_SUCCESS,
  Role.OPERATIONS,
  Role.CYBRILLA_ADMIN,
  Role.OPERATIONS_ANALYST,
];
